
import { defineComponent, ref } from "vue";
import GenreRecord from "@/model/genres/GenreRecord";
import { getHomeState } from "@/state/HomeState";
import { getState } from "@/state/IvoryState";

export default defineComponent({
  data() {
    return {
      state: getState(),
      cardStyle: {
        background:
          "linear-gradient(to left, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.45))",
      },
    };
  },
  mounted() {
    if (this.big) {
      this.$el.classList.add("big");
    }
  },
  methods: {
    updateGradient(event: MouseEvent) {
      const card = this.$refs.card as HTMLElement;
      const rect = card.getBoundingClientRect();
      const x = event.clientX - rect.left; // Get mouse X relative to the card
      const y = event.clientY - rect.top; // Get mouse Y relative to the card
      const xPercent = (x / rect.width) * 100; // Calculate X as percentage
      const yPercent = (y / rect.height) * 100; // Calculate Y as percentage

      // Update the gradient with dynamic mouse position
      this.cardStyle.background = `radial-gradient(circle at ${xPercent}% ${yPercent}%, rgba(25, 25, 25, 0.3), rgba(0, 0, 0, 0.45))`;
    },
  },
  props: {
    big: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    genre: {
      type: GenreRecord,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
