import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, renderSlot as _renderSlot, createBlock as _createBlock, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-039826c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item light" }
const _hoisted_2 = { class: "item-row" }
const _hoisted_3 = {
  key: 0,
  class: "img-container"
}
const _hoisted_4 = {
  key: 1,
  class: "ytb-img"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "meta-row" }
const _hoisted_7 = { class: "f12" }
const _hoisted_8 = {
  style: {"gap":"12px"},
  class: "item-row no-mobile"
}
const _hoisted_9 = {
  key: 0,
  class: "free-badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IvoryIcon = _resolveComponent("IvoryIcon")!
  const _component_IvoryStars = _resolveComponent("IvoryStars")!
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.song?.youtubeTranscription)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_IvoryIcon, {
              size: 60,
              icon: "play"
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("img", {
              style: {"border-radius":"6px"},
              width: "110",
              height: "85",
              src: 
            'https://i.ytimg.com/vi/' +
            _ctx.getVideoId(_ctx.song.youtubeUrl!) +
            '/hqdefault.jpg'
          
            }, null, 8, _hoisted_5)
          ])),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("b", null, _toDisplayString(_ctx.DOMUtils.limitStringSize(_ctx.song!.name, 120)), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(!_ctx.song?.youtubeTranscription ? _ctx.song!.artist : "Generated from youtube"), 1),
        _createVNode(_component_IvoryStars, {
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
          size: 18,
          onChange: _ctx.rateSong,
          displayRate: true,
          rate: _ctx.song?.rate,
          count: _ctx.voteCount
        }, null, 8, ["onChange", "rate", "count"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.song.free)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Free"))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.tailing)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.state.loggedIn() && (_ctx.state.account?.role == 5 || _ctx.song.owned))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.song.pendingRemove)
                    ? (_openBlock(), _createBlock(_component_HalfCircleSpinner, {
                        key: 0,
                        size: 30,
                        color: "#c41627"
                      }))
                    : (_openBlock(), _createBlock(_component_IvoryIcon, {
                        key: 1,
                        onClick: [
                          _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                          _cache[2] || (_cache[2] = () => _ctx.removeSong(_ctx.song))
                        ],
                        icon: "trash"
                      }))
                ], 64))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_HalfCircleSpinner, {
              size: 30,
              color: "#3deb34"
            }, null, 512), [
              [_vShow, _ctx.song.pendingStar]
            ]),
            _withDirectives(_createVNode(_component_IvoryIcon, {
              onClick: [
                _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
                _ctx.favoriteClick
              ],
              ref: "favIcon",
              icon: _ctx.getFavIcon()
            }, null, 8, ["onClick", "icon"]), [
              [_vShow, !_ctx.song.pendingStar]
            ]),
            _createVNode(_component_IvoryIcon, {
              onClick: [
                _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
                _ctx.sheetClick
              ],
              icon: "sheet"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Sheet")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_IvoryIcon, {
              onClick: [
                _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"])),
                _ctx.shareClick
              ],
              icon: "share"
            }, null, 8, ["onClick"])
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}