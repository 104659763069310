import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6648e946"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-section align-center flex col g12" }
const _hoisted_2 = { class: "flex align-center g6" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "bold genre-name light" }
const _hoisted_5 = { class: "description f14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "card",
    ref: "card",
    style: _normalizeStyle(_ctx.cardStyle),
    onMousemove: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateGradient && _ctx.updateGradient(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          width: "30",
          src: _ctx.icon
        }, null, 8, _hoisted_3),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.genre.name), 1)
      ]),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.genre.description), 1)
    ])
  ], 36))
}