
import { defineComponent } from "vue";
import { getHomeState } from "@/state/HomeState";

export default defineComponent({
  async mounted() {},
  data() {
    var state = getHomeState();
    return {
      state: state,
    };
  },

  methods: {},
});
