
import { defineComponent, nextTick } from "vue";
import { getState } from "@/state/IvoryState";
import { useRoute, useRouter } from "vue-router";
import mixpanel from "mixpanel-browser";
import * as IvoryApi from "@/IvoryApi";
import Sheet from "@/model/sheets/IvorySheet";
import SheetDrawer from "@/managers/SheetDrawer";
import * as Constants from "@/Constants";
import IvorySong from "@/model/songs/IvorySong";
import * as SoundManager from "@/managers/SoundManager";
import Keyboard from "@/components/player/keys/Keyboard.vue";
import { StaveNote } from "vexflow";
import SongPlayer from "@/managers/SongPlayer";
import IvoryNote from "@/model/songs/IvoryNote";
import IvoryStaveNote from "@/model/sheets/IvoryStaveNote";

export default defineComponent({
  components: {
    Keyboard,
  },
  data() {
    return {
      hidden: false,
      loaded: false,
      sheet: null as Sheet | null,
      song: null as IvorySong | null,
      Sheet: Sheet,
      canvas: null as HTMLCanvasElement | null,
      drawer: null as SheetDrawer | null,
      error: false,
      keyboard: null as Keyboard | null,
      displayKeyboard: false,
      songPlayer: null as SongPlayer | null,
      noteMapping: new Map() as Map<number, IvoryStaveNote[]>,
      debugText: "Loading...",
    };
  },
  beforeUnmount() {
    this.stop();
  },
  setup() {
    var router = useRouter();
    var state = getState();

    state.computeKeyboardSize();

    return {
      state: state,
      router: router,
    };
  },
  computed: {},

  methods: {
    play() {
      this.songPlayer?.play();
    },
    stop() {
      var refs = <any>this.$refs;
      var keyboard = refs.keyboard as Keyboard;
      keyboard.removeInputs();

      this.songPlayer?.stop();

      for (let note of this.drawer!.allNotes!.values()) {
        note.setStyle({ fillStyle: "black", strokeStyle: "black" });
      }

      this.drawer!.draw();

      SoundManager.stop();
    },
    onNoteStart(note: IvoryNote) {
      this.keyboard!.addInput(note.number, true, note.velocity, note.leftHand);

      let sheetNotes = this.noteMapping.get(note.index)!;

      for (let sheetNote of sheetNotes) {
        var staveNote = this.drawer?.allNotes.get(sheetNote) as StaveNote;

        var color = this.state.getNoteColorHex();

        if (note.leftHand) {
          color = this.state.getNoteColorLHHex();
        }

        staveNote.setStyle({ fillStyle: color, strokeStyle: color });

        if (!this.drawer!.isNoteVisible(staveNote)) {
          continue;
        }

        staveNote.draw();
      }
    },
    onNoteEndReal(note: IvoryNote) {
      this.keyboard!.removeSound(note.number);
    },
    onNoteEnd(note: IvoryNote) {
      let sheetNotes = this.noteMapping.get(note.index)!;

      this.keyboard!.removeInput(note.number);

      for (let sheetNote of sheetNotes) {
        var staveNote = this.drawer?.allNotes.get(sheetNote) as StaveNote;

        staveNote.setStyle({ fillStyle: "black", strokeStyle: "black" });

        if (!this.drawer!.isNoteVisible(staveNote)) {
          continue;
        }

        staveNote.draw();
      }
    },

    joinDiscord() {
      window.open(Constants.DiscordLink, "_blank");
    },
    openPlayer() {
      getState().playSongInNewTab(this.state.querySongId!);
    },
    goHome() {
      this.router.push({ name: "home" });
    },
    onMouseWheel(e: any) {
      this.drawer!.addScroll(-e.deltaY);
    },

    computeCanvasSize() {
      var refs = <any>this.$refs;

      const element = refs.header as HTMLElement;
      const rect = element.getBoundingClientRect();
      this.canvas!.width = window.innerWidth;
      this.canvas!.height =
        window.innerHeight -
        (rect.top + window.scrollY + rect.height) -
        (this.displayKeyboard ? this.state.whiteKeyHeight + 12 : 0);

      this.canvas!.style.width = this.canvas!.width + "px";
      this.canvas!.style.height = this.canvas!.height + "px";

      if (this.drawer?.renderer != null)
        this.drawer!.renderer!.resize(this.canvas!.width, this.canvas!.height);
    },
    toggleKeyboard() {
      this.displayKeyboard = !this.displayKeyboard;
      this.computeCanvasSize();

      this.drawer!.draw();
    },
    buildNoteMapping() {
      var arr = Array.from(this.drawer!.allNotes!.keys());

      for (let note of this.song?.data?.notes!) {
        var sheetNotes = arr.filter((x) =>
          x.indexes.some((x) => x == note.index)
        );

        this.noteMapping.set(note.index, []);

        for (let sheetNote of sheetNotes) {
          this.noteMapping.get(note.index)?.push(sheetNote);
        }
      }
    },
  },

  async mounted() {
    mixpanel.track("Sheet view", {});

    this.state.account = await IvoryApi.getLoggedAccount();

    this.hidden = this.state.account?.role != 5;

    if (this.hidden) {
      return;
    }
    const params = new URLSearchParams(window.location.search);
    const tempo = params.get("tempo");

    if (this.state.account != null) {
      this.state.onAccountReceived();
    }
    this.sheet = await IvoryApi.getSheet(
      this.state.querySongId!,
      tempo ? tempo : "120"
    );

    console.log(this.sheet);
    this.song = await IvoryApi.requestSong(this.state.querySongId!, true);

    if (this.sheet == null || this.song == null) {
      this.error = true;
      return;
    }

    await SoundManager.initialize("ivory-keys", (v: number) => {
      this.debugText = Math.round(v * 100) + "%";
    });

    var refs = <any>this.$refs;

    this.keyboard = refs.keyboard;

    this.canvas = refs.canvas as HTMLCanvasElement;

    this.computeCanvasSize();

    this.canvas.addEventListener("wheel", (e) => {
      this.onMouseWheel(e);
    });

    this.drawer = new SheetDrawer(this.canvas, this.sheet!);
    this.drawer.initialize();

    this.drawer.build();
    this.drawer!.draw();

    this.songPlayer = new SongPlayer(
      this.song!,
      this.onNoteStart,
      this.onNoteEnd,
      this.onNoteEndReal
    );

    this.buildNoteMapping();

    this.loaded = true;
  },
});
