import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4c8697e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bottom-bar" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "title-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IvoryIcon = _resolveComponent("IvoryIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "title", {}, undefined, true)
          ]),
          (_ctx.closeButton)
            ? (_openBlock(), _createBlock(_component_IvoryIcon, {
                key: 0,
                icon: "close",
                onClick: _ctx.close
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "body", {}, undefined, true)
      ])
    ])
  ]))
}