
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";

export default defineComponent({
  name: "OptimizedModal",
  props: {
    title: {
      type: String,
      default: "Info",
      required: true,
    },
    noHeader: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      state: getState(),
      context: null as any,
      isActive: false,
    };
  },
  mounted() {
    // No global event needed here since we rely on focus and @keydown on the modal element.
  },
  beforeUnmount() {
    // Cleanup if necessary
  },
  methods: {
    open(ctx: any = null) {
      this.context = ctx;
      this.isActive = true;
    },
    close() {
      this.isActive = false;
    },
    handleEscape() {
      this.close();
    },
    afterLeave() {
      this.$emit("onClose");
    },
  },
});
