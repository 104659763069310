
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      default: "Infos",
    },
    type: {
      type: String,
      default: "info",
      required: false,
    },
  },
  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {},
  },
});
