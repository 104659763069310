import { defineStore } from "pinia";
import { HomePageEnum } from "./HomePageEnum";
import GenreRecord from "@/model/genres/GenreRecord";

interface HomeState {
  page: HomePageEnum;
  genres: GenreRecord[] | null;
  search: string;
  transcriptionUrl: string;
  transcriptionFileName: string;
  transcriptionAuthor: string;
}

const defaultState = {
  page: HomePageEnum.Search,
  genres: [],
  search: "",
  transcriptionUrl: "",
  transcriptionFileName: "",
  transcriptionAuthor: "",
};
export const getHomeState = defineStore("HomeState", {
  state: (): HomeState => {
    return Object.assign({}, defaultState);
  },
  actions: {
    navigate(page: HomePageEnum) {
      if (page == HomePageEnum.Transcribe) {
        this.transcriptionUrl = "";
        this.transcriptionFileName = "";
        this.transcriptionAuthor = "";
      }
      this.page = page;
    },
  },
});
