import IvorySong from "@/model/songs/IvorySong";

export default class SongPlayer {
  song: IvorySong;
  scheduledEvents: any[] = [];

  onNoteStart: Function;
  onNoteEnd: Function;
  onNoteEndReal: Function;

  audioContext: AudioContext | null = null;
  isPlaying: boolean = false;
  schedulerId: number | null = null;

  constructor(
    song: IvorySong,
    onNoteStart: Function,
    onNoteEnd: Function,
    onNoteEndReal: Function
  ) {
    this.song = song;
    this.onNoteStart = onNoteStart;
    this.onNoteEnd = onNoteEnd;
    this.onNoteEndReal = onNoteEndReal;
  }

  play() {
    this.audioContext = new window.AudioContext();
    // Resume the audio context if it's suspended (browser autoplay policies)
    if (this.audioContext.state === "suspended") {
      this.audioContext.resume();
    }

    this.isPlaying = true;
    const startTime = this.audioContext.currentTime;

    // Schedule all note events
    for (let note of this.song.data!.notes) {
      const noteStartTime = startTime + note.start;
      const noteEndTime = noteStartTime + note.duration;
      const noteRealEndTime = noteStartTime + note.realDuration;

      this.scheduledEvents.push({
        time: noteStartTime,
        type: "start",
        note: note,
      });

      this.scheduledEvents.push({
        time: noteEndTime,
        type: "end",
        note: note,
      });

      this.scheduledEvents.push({
        time: noteRealEndTime,
        type: "realEnd",
        note: note,
      });
    }

    // Sort events by time to ensure they are processed in order
    this.scheduledEvents.sort((a, b) => a.time - b.time);

    // Start the scheduler loop
    this.scheduler();
  }

  scheduler() {
    if (!this.isPlaying) return;

    const currentTime = this.audioContext!.currentTime;

    // Collect events that need to be executed
    const eventsToExecute: any[] = [];

    // Use a look-ahead time to account for the delay between frames
    const lookAheadTime = 0.01; // 100 milliseconds

    while (
      this.scheduledEvents.length > 0 &&
      this.scheduledEvents[0].time <= currentTime + lookAheadTime
    ) {
      const event = this.scheduledEvents.shift();
      eventsToExecute.push(event);
    }

    // Execute the collected events
    for (const event of eventsToExecute) {
      this.executeEvent(event);
    }

    // Continue the scheduler loop using requestAnimationFrame
    this.schedulerId = window.requestAnimationFrame(this.scheduler.bind(this));
  }

  executeEvent(event: any) {
    if (!this.isPlaying) return;

    switch (event.type) {
      case "start":
        this.onNoteStart(event.note);
        break;
      case "end":
        this.onNoteEnd(event.note);
        break;
      case "realEnd":
        this.onNoteEndReal(event.note);
        break;
    }
  }

  stop() {
    this.isPlaying = false;

    if (this.schedulerId !== null) {
      window.cancelAnimationFrame(this.schedulerId);
      this.schedulerId = null;
    }

    // Clear any pending scheduled events
    this.scheduledEvents = [];

    // Close the audio context to release resources
    if (this.audioContext!.state !== "closed") {
      this.audioContext!.close();
    }
  }
}
