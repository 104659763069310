import IvoryMeasure from "@/model/sheets/IvoryMeasure";
import IvoryStave from "@/model/sheets/IvoryStave";
import { Beam, Stave, StaveConnector, StaveTie, Voice } from "vexflow";

export default class VirtualStave {
  ivoryMesure: IvoryMeasure;

  staveId: number;
  stave: Stave;

  beams: Beam[] = [];
  ties: StaveTie[] = [];
  voice: Voice;
  connectors: StaveConnector[] = [];

  initialX: number = 0;
  initialY: number = 0;

  constructor(
    staveId: number,
    ivoryMesure: IvoryMeasure,
    stave: Stave,
    beams: Beam[],
    ties: StaveTie[],
    voice: Voice,
    connectors: StaveConnector[],
    initialX: number,
    initialY: number
  ) {
    this.staveId = staveId;
    this.ivoryMesure = ivoryMesure;
    this.stave = stave;
    this.beams = beams;
    this.ties = ties;
    this.voice = voice;
    this.connectors = connectors;
    this.initialX = initialX;
    this.initialY = initialY;
  }

  public draw(context: any) {
    this.stave.draw();
    this.voice.draw();

    for (let beam of this.beams) {
      beam.draw();
    }

    for (let connectors of this.connectors) {
      connectors.draw();
    }

    for (let tie of this.ties) {
      tie.draw();
    }

    if (this.staveId === 0) {
      context!.setFont("Arial", 8, "normal"); // Change font, size, and style

      const measureX = this.stave.getX(); // Get x position for the first note
      const measureY = this.stave.getY() + 30; // Position above the stave
      context!.fillText(this.ivoryMesure.number.toString(), measureX, measureY);
    }
  }
  visible(canvas: HTMLCanvasElement, scrollY: number) {
    let delta = -scrollY;

    var lower = this.initialY < delta + 700;

    var upper = this.initialY > delta - 100;

    return lower && upper;
  }
}
