import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74b3027e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-button" }
const _hoisted_2 = ["accept"]
const _hoisted_3 = { class: "input-button-body f12" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-row bold f14 light" }
const _hoisted_6 = {
  key: 0,
  class: "f10 bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      accept: _ctx.accept,
      type: "file"
    }, null, 40, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            width: "25",
            height: "25",
            src: '/ico/' + _ctx.icon + '.svg'
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_HalfCircleSpinner, {
            key: 1,
            color: "#c4c4c4",
            style: {"width":"30px","height":"30px","margin-right":"6px","margin-bottom":"3px"}
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        (_ctx.fileName != null)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.fileName), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}