import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e98994a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "light",
  style: {"flex-grow":"1"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    class: "dropdown-item"
  }, [
    _renderSlot(_ctx.$slots, "heading", {}, undefined, true),
    (_ctx.displayTitle)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.getTitle()), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }, [
      _renderSlot(_ctx.$slots, "tailing", {}, undefined, true)
    ])
  ]))
}