
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import IvorySong from "@/model/songs/IvorySong";
import * as IvoryApi from "@/IvoryApi";
import SongRow from "./SongRow.vue";
import router from "@/router";
import ProcessingTask from "@/model/ProcessingTask";
import TranscriptionRow from "@/components/home/TranscriptionRow.vue";
import TranscriptionResult, {
  TranscriptionStatus,
} from "@/model/TranscriptionResult";
import GenreRow from "@/components/home/GenreRow.vue";
import { getHomeState } from "@/state/HomeState";
import GenreRecord from "@/model/genres/GenreRecord";
import { TranscriptionSource } from "@/model/TranscriptionSource";
import * as Modals from "@/utils/Modals";
import mixpanel from "mixpanel-browser";
import { HomePageEnum } from "@/state/HomePageEnum";
import IvoryButton from "../global/IvoryButton.vue";
import IvoryModal from "../global/IvoryModal.vue";

export default defineComponent({
  data() {
    var state = getState();
    var homeState = getHomeState();

    return {
      TranscriptionSource: TranscriptionSource,
      tasks: [] as ProcessingTask[] | null,
      state: state,
      HomePageEnum: HomePageEnum,
      homeState: homeState,
      interval: null as number | null,
      selectedGenre: null as GenreRecord | null,
      transcriptionSource: null as TranscriptionSource | null,
      myUploads: null as IvorySong[] | null,
      transcriptionStatus: "",
    };
  },

  mounted() {
    if (this.state.loggedIn()) {
      this.interval = setInterval(this.updateTasks, 1000);
      this.updateTasks();
    }

    if (this.homeState.transcriptionUrl != "") {
      this.onYoutubeReady();
    }
  },
  unmounted() {
    if (this.interval != null) {
      clearInterval(this.interval!);
    }
  },
  methods: {
    subscribeClick() {
      getHomeState().navigate(HomePageEnum.Profile);
    },
    async removeSong(song: IvorySong) {
      song.pendingRemove = true;
      await IvoryApi.removeSong(song.id);
    },
    onSongClick(song: IvorySong) {
      var state = getState();

      state.querySongId = song.id;
      router.push("player");
    },
    onLoginClick() {
      router.push("login");
    },

    onGenreClick(genre: GenreRecord) {
      var refs = <any>this.$refs;

      this.selectedGenre = genre;
    },
    async updateTasks() {
      var tasks = await IvoryApi.getRunningTasks();

      this.tasks = tasks!.reverse()!;

      this.myUploads = await IvoryApi.getMyUploads();
    },

    async onFileUpload(event: any) {
      var refs = <any>this.$refs;
      var file = event.target.files[0];

      const removeExtension = (filename) =>
        filename.split(".").slice(0, -1).join(".") || filename;

      this.homeState.transcriptionFileName = removeExtension(file.name);

      this.transcriptionSource = TranscriptionSource.File;

      this.openTranscribeModal();
    },
    handleTranscriptionResult(result: TranscriptionResult) {
      var refs = <any>this.$refs;

      if (result?.status == TranscriptionStatus.Ok) {
        mixpanel.track("Transcribe", {});
        refs.transModal.close();
        this.updateTasks();
      } else if (result?.status == TranscriptionStatus.AlreadyExists) {
        this.transcriptionStatus =
          "This piece was already transcribed, you can access it using search.";
      } else if (result?.status == TranscriptionStatus.AlreadyProcessing) {
        this.transcriptionStatus =
          "This piece is already processing. Please wait until its complete.";
      } else if (result?.status == TranscriptionStatus.InvalidUrl) {
        this.transcriptionStatus =
          "The link you provided is not a valid youtube link.";
      } else if (result.status == TranscriptionStatus.MidiImported) {
        this.transcriptionStatus = "Your file has been added to the library.";
      } else if (result.status == TranscriptionStatus.NotSubscribed) {
        this.transcriptionStatus =
          "You need to be subscribed to perform this action.";
      } else if (result.status == TranscriptionStatus.Error) {
        this.transcriptionStatus =
          "Unable to process the video. It may be protected by copyright. Please try transcribing another video.";
      } else if (result.status == TranscriptionStatus.TooLong) {
        this.transcriptionStatus =
          "The video is too long. Please try to transcribe a shorter piece.";
      }
    },
    openTranscribeModal() {
      var refs = <any>this.$refs;
      this.transcriptionStatus = "";
      refs.transModal.open();
    },
    async transcribe() {
      var homeState = getHomeState();
      var refs = <any>this.$refs;

      refs.transModal.open();
      if (this.selectedGenre == null) {
        this.transcriptionStatus = "Please select a genre before transcribing.";
        return;
      }

      if (this.transcriptionSource == TranscriptionSource.Youtube) {
        var url = homeState.transcriptionUrl;

        refs.startBtn.toggleLoading(true);
        var result = await IvoryApi.requestProcessingYoutube(
          url,
          this.selectedGenre!.id
        );

        this.handleTranscriptionResult(result!);
        refs.startBtn.toggleLoading(false);

        if (result?.status == TranscriptionStatus.Ok) {
          homeState.transcriptionUrl = "";
          homeState.transcriptionFileName = "";
          homeState.transcriptionAuthor = "";
        }
      } else if (this.transcriptionSource == TranscriptionSource.File) {
        var file = refs.fileInput.getFile();
        var name = homeState.transcriptionFileName;
        var author = homeState.transcriptionAuthor;

        if (name.trim() == "" || author.trim() == "") {
          this.transcriptionStatus =
            "Please specify a title and an author before transcribing.";

          return;
        }

        refs.startBtn.toggleLoading(true);

        let result = await IvoryApi.uploadFile(
          name,
          author,
          this.selectedGenre!.id,
          file
        );

        this.handleTranscriptionResult(result!);

        refs.startBtn.toggleLoading(false);

        this.updateTasks();
      }
    },
    async onYoutubeReady() {
      this.transcriptionSource = TranscriptionSource.Youtube;
      this.openTranscribeModal();
    },
  },
  components: { TranscriptionRow, GenreRow, SongRow },
});
