export default class GenreRecord {
  public id: number = 0;

  public name: string = "";

  public description: string = "";

  public icon: string = "";

  constructor(id: number, name: string, description: string, icon: string) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.icon = icon;
  }

  static fromJson(data: any) {
    return new GenreRecord(data.id, data.name, data.description, data.icon);
  }
}
